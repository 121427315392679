import * as React from 'react'
import Layout from '../components/Layout'
import PageBreadcumb from '@/components/common/PageBreadcumb'
import About from '@/components/About'
import { useLocation } from '@reach/router'
import SEO from '@/components/seo'

const Ab: React.FC<{}> = () => {
  return (
    <Layout>
      <About />
      <SEO title="About Us" canonical={`${useLocation().host}`} />
    </Layout>
  )
}

export default Ab
