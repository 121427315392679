import React from 'react'
import BannerAbout from './Banner'
import Ab from './about'

const About = () => {
  return (
    <>
      <BannerAbout />
      <Ab />
    </>
  )
}

export default About
