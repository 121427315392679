import React from 'react'
import { Devices } from '@/hooks/useDevice'
import styled from 'styled-components'
import ImageBackground from '@/components/LazyBackgroundImage/ImageBackground'

const BannerAbout = () => {
  return (
    <BannerWrapper>
      <ImageBackground name="Hero.png" classes="banner-about">
        <LogoWrapper className="logo-wrapper">
          <img src="/images/logobanner.svg" />
        </LogoWrapper>
      </ImageBackground>
    </BannerWrapper>
  )
}

const BannerWrapper = styled.div`
  position: relative;

  @media (min-width: 1201px) {
    height: 425px;
  }
  @media (min-width: 769px) and (max-width: 1200px) {
    height: 302px;
  }
  @media ${Devices.mobile.mediaQuery} {
    height: 340px;
  }

  & img {
    height: 100%;
    background-position: right center;

    @media ${Devices.mobile.mediaQuery} {
      height: 100%;
      object-fit: cover;
      object-position: right;
    }
  }
`

const LogoWrapper = styled.div`
  padding: 53px;
  background: #ffffff;
  box-shadow: 0px 3.5265307426452637px 8.816327095031738px 0px #00000040;
  border-radius: 9px;
  height: auto;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 430px;
  display: flex;
  justify-content: center;
  & img {
    width: 100%;
  }
  @media (min-width: 1201px) {
    width: 430px;
    top: 78%;
  }
  @media (min-width: 768px) and (max-width: 1200px) {
    width: 342px;
    top: 81%;
  }
  @media (max-width: 767px) {
    display: none !important;
  }
`

export default BannerAbout
