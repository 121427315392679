import { Devices } from '@/hooks/useDevice'
import { useViewport } from '@/hooks/useViewport'
import React from 'react'
import styled from 'styled-components'

const Ab = () => {
  const { isMobile } = useViewport()

  return (
    <AboutArea className="about-area">
      <Container className="container">
        {isMobile && (
          <WelcomeWrapper>
            <LogoImage src="/images/logobanner.svg" alt="LogoImage" />
            <Title mobile={isMobile}>
              Welcome to <br /> KAT Electronics <br />
              Corporation
            </Title>
            <Content>
              A reliable security system safeguards both material assets and the well-being of
              individuals. From preventing theft to early detection of emergencies like fires, these
              systems act as a protective shield.
            </Content>
            <Content>
              Having a reliable security system provides peace of mind to both owners and employees.
              Knowing that the environment is protected allows them to focus on daily activities
              without undue concerns about the security of the premises.
            </Content>
          </WelcomeWrapper>
        )}
        <AbWrapper>
          <Title mobile={isMobile}>About Us</Title>
          <Content>
            At KAT Electronics, our commitment focuses on enhancing security standards through
            customized safety solutions that address the unique requirements of both home and
            business security. Our reliable services leverage cutting-edge technology to ensure the
            highest level of protection for our clients.
          </Content>
          <Content>
            We specialize in comprehensive secure solutions for residential and commercial
            electrical, security, and fire systems in the Miami-Dade, Broward, and Pompano areas,
            dedicated to securing your present for a safer tomorrow.
          </Content>
          <SubTitle mobile={isMobile}>Our Team</SubTitle>
          <Content>
            With extensive expertise, our certified staff guarantees a holistic service, managing
            installations and ensuring continuous monitoring and testing.
          </Content>
          <Content>
            Essential to our purpose is the dedication of our engineering team, who embrace the
            uniqueness of each client. Our design approach is built on the premise of thoroughly
            understanding the specific needs of every client.
          </Content>
          <Content>
            From conception to fruition, our engineers craft bespoke solutions, melding cutting-edge
            technology with a profound understanding of each environment's distinct demands. Whether
            a singular system or a complex integration of fire and burglary alarms, we strive to
            design solutions that not only meet standards but exceed expectations, ensuring maximum
            protection and peace of mind for our clients.
          </Content>

          <BottomTitle>Safety Solutions for Electrical, Security and Fire Systems</BottomTitle>
        </AbWrapper>
      </Container>
    </AboutArea>
  )
}

const AboutArea = styled.div`
  padding-top: 180px;
  padding-bottom: 30px;
  @media (max-width: 767px) {
    padding-top: 0px;
  }
`

const Container = styled.div`
  @media ${Devices.mobile.mediaQuery} {
    padding: 0 24px !important;
    max-width: 100%;
  }
`

const AbWrapper = styled.div`
  max-width: 650px;
  margin: 0 auto;
`

const Title = styled.h3<{ mobile: boolean }>`
  color: #00abec;
  font-size: ${(props) => (props.mobile ? '24px' : '40px')};
  font-family: BankGothic Md BT;
  font-weight: 400;
  text-align: ${(props) => (props.mobile ? 'center' : 'left')};
`

const SubTitle = styled.h3<{ mobile: boolean }>`
  color: #00abec;
  font-size: 24px;
  font-family: BankGothic Md BT;
  font-weight: 400;
  text-align: ${(props) => (props.mobile ? 'center' : 'left')};
`

const BottomTitle = styled.h2`
  color: #00abec;
  font-size: 34px;
  font-family: BankGothic Md BT;
  font-weight: 400;
  text-align: center;
  padding-top: 44px;
`
const Content = styled.p`
  font-family: Arial;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
`

const WelcomeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  padding-bottom: 70px;
`

const LogoImage = styled.img`
  margin: 0 auto;
  width: 45%;
  margin: 40px auto 48px auto;
`

export default Ab
